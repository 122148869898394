import { setupCoralogix } from "@helium10/re-features";
import { useEffect } from "react";

import { version } from "../../../package.json";

const getEnvTag = () => {
  switch (process.env.REACT_APP_STATE) {
    case "prod":
      return "prd";
    case "stage":
      return "stg";
    case "dev":
      return "dev";
    default:
      return "unknown";
  }
};

function getSubPathAsToolName() {
  // re-walmart-container serves many tools, use route name as tool name
  return window.location.pathname.split("/")[1];
}

export function useSetupCoralogix() {
  useEffect(() => {
    setupCoralogix({
      publicKey: "cxtp_M8etMw1iJFfFUBxR8MvAqdksqDhoe6",
      application: "re-walmart-container",
      version,
      coralogixDomain: "US2",
      labels: {
        env: getEnvTag(),
        tool: getSubPathAsToolName(),
      },
    });
  }, []);
}
